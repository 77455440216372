import { default as _91_46_46_46slug_93Xq1SoyUybgMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/[...slug].vue?macro=true";
import { default as indexEb8T9VS0GmMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/index.vue?macro=true";
import { default as logout3OyWQTP9l3Meta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/logout.vue?macro=true";
import { default as cadastrarIucvF9sEATMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/lojas/cadastrar.vue?macro=true";
import { default as _91usuario_93doCHcqrd32Meta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/lojas/editar/[id]/convidar/[usuario].vue?macro=true";
import { default as indexDb6WXszEDrMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/lojas/editar/[id]/index.vue?macro=true";
import { default as indexPjbhLTcdkgMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/lojas/index.vue?macro=true";
import { default as mapaIQI73Wn4M3Meta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/lojas/mapa.vue?macro=true";
import { default as _91id_93NZVKFhVQ1bMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/lojas/visualizar/[id].vue?macro=true";
import { default as indexyMtCKt5XTyMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/performance/index.vue?macro=true";
import { default as cadastrarQixzofRXBrMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/permissoes/cadastrar.vue?macro=true";
import { default as _91id_93sVO68EvrebMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/permissoes/editar/[id].vue?macro=true";
import { default as indexZBnWMtNPzXMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/permissoes/index.vue?macro=true";
import { default as _91id_93cpbH8HyWkxMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/permissoes/visualizar/[id].vue?macro=true";
import { default as problema084cuPLG2XMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/problema.vue?macro=true";
import { default as cadastrarC1jtFDF85xMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/redes/cadastrar.vue?macro=true";
import { default as _91usuario_93WR05OdeBiLMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/redes/editar/[id]/convidar/[usuario].vue?macro=true";
import { default as index1kEqZmYchsMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/redes/editar/[id]/index.vue?macro=true";
import { default as indexlye1MOKYRKMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/redes/index.vue?macro=true";
import { default as _91id_93Sj22WLDFMCMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/redes/visualizar/[id].vue?macro=true";
import { default as indexF7J4gZpgXRMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/sh/cadastrar/index.vue?macro=true";
import { default as _91usuario_93xsMCoMZ3zzMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/sh/editar/[id]/convidar/[usuario].vue?macro=true";
import { default as indexVEaIqavYhHMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/sh/editar/[id]/index.vue?macro=true";
import { default as index2I2nHDr6EEMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/sh/index.vue?macro=true";
import { default as _91id_93WUjSEGmkZFMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/sh/visualizar/[id].vue?macro=true";
import { default as _91token_93Bq0nkxm9p0Meta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/sso/[token].vue?macro=true";
import { default as cadastrarZIHtgkCw8cMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/usuarios/cadastrar.vue?macro=true";
import { default as _91id_93vGqeVxuYXYMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/usuarios/editar/[id].vue?macro=true";
import { default as indexkhuSSTnNJrMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/usuarios/importar/index.vue?macro=true";
import { default as indexluXXNwUYQAMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/usuarios/index.vue?macro=true";
import { default as _91id_93SRQFxfX50eMeta } from "/home/runner/work/cadastro-portal/cadastro-portal/pages/usuarios/visualizar/[id].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93Xq1SoyUybgMeta?.name ?? "slug",
    path: _91_46_46_46slug_93Xq1SoyUybgMeta?.path ?? "/:slug(.*)*",
    children: [],
    meta: _91_46_46_46slug_93Xq1SoyUybgMeta || {},
    alias: _91_46_46_46slug_93Xq1SoyUybgMeta?.alias || [],
    redirect: _91_46_46_46slug_93Xq1SoyUybgMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexEb8T9VS0GmMeta?.name ?? "index",
    path: indexEb8T9VS0GmMeta?.path ?? "/",
    children: [],
    meta: indexEb8T9VS0GmMeta || {},
    alias: indexEb8T9VS0GmMeta?.alias || [],
    redirect: indexEb8T9VS0GmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logout3OyWQTP9l3Meta?.name ?? "logout",
    path: logout3OyWQTP9l3Meta?.path ?? "/logout",
    children: [],
    meta: logout3OyWQTP9l3Meta || {},
    alias: logout3OyWQTP9l3Meta?.alias || [],
    redirect: logout3OyWQTP9l3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: cadastrarIucvF9sEATMeta?.name ?? "lojas-cadastrar",
    path: cadastrarIucvF9sEATMeta?.path ?? "/lojas/cadastrar",
    children: [],
    meta: cadastrarIucvF9sEATMeta || {},
    alias: cadastrarIucvF9sEATMeta?.alias || [],
    redirect: cadastrarIucvF9sEATMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/lojas/cadastrar.vue").then(m => m.default || m)
  },
  {
    name: _91usuario_93doCHcqrd32Meta?.name ?? "lojas-editar-id-convidar-usuario",
    path: _91usuario_93doCHcqrd32Meta?.path ?? "/lojas/editar/:id/convidar/:usuario",
    children: [],
    meta: _91usuario_93doCHcqrd32Meta || {},
    alias: _91usuario_93doCHcqrd32Meta?.alias || [],
    redirect: _91usuario_93doCHcqrd32Meta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/lojas/editar/[id]/convidar/[usuario].vue").then(m => m.default || m)
  },
  {
    name: indexDb6WXszEDrMeta?.name ?? "lojas-editar-id",
    path: indexDb6WXszEDrMeta?.path ?? "/lojas/editar/:id",
    children: [],
    meta: indexDb6WXszEDrMeta || {},
    alias: indexDb6WXszEDrMeta?.alias || [],
    redirect: indexDb6WXszEDrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/lojas/editar/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPjbhLTcdkgMeta?.name ?? "lojas",
    path: indexPjbhLTcdkgMeta?.path ?? "/lojas",
    children: [],
    meta: indexPjbhLTcdkgMeta || {},
    alias: indexPjbhLTcdkgMeta?.alias || [],
    redirect: indexPjbhLTcdkgMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/lojas/index.vue").then(m => m.default || m)
  },
  {
    name: mapaIQI73Wn4M3Meta?.name ?? "lojas-mapa",
    path: mapaIQI73Wn4M3Meta?.path ?? "/lojas/mapa",
    children: [],
    meta: mapaIQI73Wn4M3Meta || {},
    alias: mapaIQI73Wn4M3Meta?.alias || [],
    redirect: mapaIQI73Wn4M3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/lojas/mapa.vue").then(m => m.default || m)
  },
  {
    name: _91id_93NZVKFhVQ1bMeta?.name ?? "lojas-visualizar-id",
    path: _91id_93NZVKFhVQ1bMeta?.path ?? "/lojas/visualizar/:id",
    children: [],
    meta: _91id_93NZVKFhVQ1bMeta || {},
    alias: _91id_93NZVKFhVQ1bMeta?.alias || [],
    redirect: _91id_93NZVKFhVQ1bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/lojas/visualizar/[id].vue").then(m => m.default || m)
  },
  {
    name: indexyMtCKt5XTyMeta?.name ?? "performance",
    path: indexyMtCKt5XTyMeta?.path ?? "/performance",
    children: [],
    meta: indexyMtCKt5XTyMeta || {},
    alias: indexyMtCKt5XTyMeta?.alias || [],
    redirect: indexyMtCKt5XTyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/performance/index.vue").then(m => m.default || m)
  },
  {
    name: cadastrarQixzofRXBrMeta?.name ?? "permissoes-cadastrar",
    path: cadastrarQixzofRXBrMeta?.path ?? "/permissoes/cadastrar",
    children: [],
    meta: cadastrarQixzofRXBrMeta || {},
    alias: cadastrarQixzofRXBrMeta?.alias || [],
    redirect: cadastrarQixzofRXBrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/permissoes/cadastrar.vue").then(m => m.default || m)
  },
  {
    name: _91id_93sVO68EvrebMeta?.name ?? "permissoes-editar-id",
    path: _91id_93sVO68EvrebMeta?.path ?? "/permissoes/editar/:id",
    children: [],
    meta: _91id_93sVO68EvrebMeta || {},
    alias: _91id_93sVO68EvrebMeta?.alias || [],
    redirect: _91id_93sVO68EvrebMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/permissoes/editar/[id].vue").then(m => m.default || m)
  },
  {
    name: indexZBnWMtNPzXMeta?.name ?? "permissoes",
    path: indexZBnWMtNPzXMeta?.path ?? "/permissoes",
    children: [],
    meta: indexZBnWMtNPzXMeta || {},
    alias: indexZBnWMtNPzXMeta?.alias || [],
    redirect: indexZBnWMtNPzXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/permissoes/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cpbH8HyWkxMeta?.name ?? "permissoes-visualizar-id",
    path: _91id_93cpbH8HyWkxMeta?.path ?? "/permissoes/visualizar/:id",
    children: [],
    meta: _91id_93cpbH8HyWkxMeta || {},
    alias: _91id_93cpbH8HyWkxMeta?.alias || [],
    redirect: _91id_93cpbH8HyWkxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/permissoes/visualizar/[id].vue").then(m => m.default || m)
  },
  {
    name: problema084cuPLG2XMeta?.name ?? "problema",
    path: problema084cuPLG2XMeta?.path ?? "/problema",
    children: [],
    meta: problema084cuPLG2XMeta || {},
    alias: problema084cuPLG2XMeta?.alias || [],
    redirect: problema084cuPLG2XMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/problema.vue").then(m => m.default || m)
  },
  {
    name: cadastrarC1jtFDF85xMeta?.name ?? "redes-cadastrar",
    path: cadastrarC1jtFDF85xMeta?.path ?? "/redes/cadastrar",
    children: [],
    meta: cadastrarC1jtFDF85xMeta || {},
    alias: cadastrarC1jtFDF85xMeta?.alias || [],
    redirect: cadastrarC1jtFDF85xMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/redes/cadastrar.vue").then(m => m.default || m)
  },
  {
    name: _91usuario_93WR05OdeBiLMeta?.name ?? "redes-editar-id-convidar-usuario",
    path: _91usuario_93WR05OdeBiLMeta?.path ?? "/redes/editar/:id/convidar/:usuario",
    children: [],
    meta: _91usuario_93WR05OdeBiLMeta || {},
    alias: _91usuario_93WR05OdeBiLMeta?.alias || [],
    redirect: _91usuario_93WR05OdeBiLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/redes/editar/[id]/convidar/[usuario].vue").then(m => m.default || m)
  },
  {
    name: index1kEqZmYchsMeta?.name ?? "redes-editar-id",
    path: index1kEqZmYchsMeta?.path ?? "/redes/editar/:id",
    children: [],
    meta: index1kEqZmYchsMeta || {},
    alias: index1kEqZmYchsMeta?.alias || [],
    redirect: index1kEqZmYchsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/redes/editar/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlye1MOKYRKMeta?.name ?? "redes",
    path: indexlye1MOKYRKMeta?.path ?? "/redes",
    children: [],
    meta: indexlye1MOKYRKMeta || {},
    alias: indexlye1MOKYRKMeta?.alias || [],
    redirect: indexlye1MOKYRKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/redes/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Sj22WLDFMCMeta?.name ?? "redes-visualizar-id",
    path: _91id_93Sj22WLDFMCMeta?.path ?? "/redes/visualizar/:id",
    children: [],
    meta: _91id_93Sj22WLDFMCMeta || {},
    alias: _91id_93Sj22WLDFMCMeta?.alias || [],
    redirect: _91id_93Sj22WLDFMCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/redes/visualizar/[id].vue").then(m => m.default || m)
  },
  {
    name: indexF7J4gZpgXRMeta?.name ?? "sh-cadastrar",
    path: indexF7J4gZpgXRMeta?.path ?? "/sh/cadastrar",
    children: [],
    meta: indexF7J4gZpgXRMeta || {},
    alias: indexF7J4gZpgXRMeta?.alias || [],
    redirect: indexF7J4gZpgXRMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/sh/cadastrar/index.vue").then(m => m.default || m)
  },
  {
    name: _91usuario_93xsMCoMZ3zzMeta?.name ?? "sh-editar-id-convidar-usuario",
    path: _91usuario_93xsMCoMZ3zzMeta?.path ?? "/sh/editar/:id/convidar/:usuario",
    children: [],
    meta: _91usuario_93xsMCoMZ3zzMeta || {},
    alias: _91usuario_93xsMCoMZ3zzMeta?.alias || [],
    redirect: _91usuario_93xsMCoMZ3zzMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/sh/editar/[id]/convidar/[usuario].vue").then(m => m.default || m)
  },
  {
    name: indexVEaIqavYhHMeta?.name ?? "sh-editar-id",
    path: indexVEaIqavYhHMeta?.path ?? "/sh/editar/:id",
    children: [],
    meta: indexVEaIqavYhHMeta || {},
    alias: indexVEaIqavYhHMeta?.alias || [],
    redirect: indexVEaIqavYhHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/sh/editar/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index2I2nHDr6EEMeta?.name ?? "sh",
    path: index2I2nHDr6EEMeta?.path ?? "/sh",
    children: [],
    meta: index2I2nHDr6EEMeta || {},
    alias: index2I2nHDr6EEMeta?.alias || [],
    redirect: index2I2nHDr6EEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/sh/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WUjSEGmkZFMeta?.name ?? "sh-visualizar-id",
    path: _91id_93WUjSEGmkZFMeta?.path ?? "/sh/visualizar/:id",
    children: [],
    meta: _91id_93WUjSEGmkZFMeta || {},
    alias: _91id_93WUjSEGmkZFMeta?.alias || [],
    redirect: _91id_93WUjSEGmkZFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/sh/visualizar/[id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93Bq0nkxm9p0Meta?.name ?? "sso-token",
    path: _91token_93Bq0nkxm9p0Meta?.path ?? "/sso/:token",
    children: [],
    meta: _91token_93Bq0nkxm9p0Meta || {},
    alias: _91token_93Bq0nkxm9p0Meta?.alias || [],
    redirect: _91token_93Bq0nkxm9p0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/sso/[token].vue").then(m => m.default || m)
  },
  {
    name: cadastrarZIHtgkCw8cMeta?.name ?? "usuarios-cadastrar",
    path: cadastrarZIHtgkCw8cMeta?.path ?? "/usuarios/cadastrar",
    children: [],
    meta: cadastrarZIHtgkCw8cMeta || {},
    alias: cadastrarZIHtgkCw8cMeta?.alias || [],
    redirect: cadastrarZIHtgkCw8cMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/usuarios/cadastrar.vue").then(m => m.default || m)
  },
  {
    name: _91id_93vGqeVxuYXYMeta?.name ?? "usuarios-editar-id",
    path: _91id_93vGqeVxuYXYMeta?.path ?? "/usuarios/editar/:id",
    children: [],
    meta: _91id_93vGqeVxuYXYMeta || {},
    alias: _91id_93vGqeVxuYXYMeta?.alias || [],
    redirect: _91id_93vGqeVxuYXYMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/usuarios/editar/[id].vue").then(m => m.default || m)
  },
  {
    name: indexkhuSSTnNJrMeta?.name ?? "usuarios-importar",
    path: indexkhuSSTnNJrMeta?.path ?? "/usuarios/importar",
    children: [],
    meta: indexkhuSSTnNJrMeta || {},
    alias: indexkhuSSTnNJrMeta?.alias || [],
    redirect: indexkhuSSTnNJrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/usuarios/importar/index.vue").then(m => m.default || m)
  },
  {
    name: indexluXXNwUYQAMeta?.name ?? "usuarios",
    path: indexluXXNwUYQAMeta?.path ?? "/usuarios",
    children: [],
    meta: indexluXXNwUYQAMeta || {},
    alias: indexluXXNwUYQAMeta?.alias || [],
    redirect: indexluXXNwUYQAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/usuarios/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93SRQFxfX50eMeta?.name ?? "usuarios-visualizar-id",
    path: _91id_93SRQFxfX50eMeta?.path ?? "/usuarios/visualizar/:id",
    children: [],
    meta: _91id_93SRQFxfX50eMeta || {},
    alias: _91id_93SRQFxfX50eMeta?.alias || [],
    redirect: _91id_93SRQFxfX50eMeta?.redirect || undefined,
    component: () => import("/home/runner/work/cadastro-portal/cadastro-portal/pages/usuarios/visualizar/[id].vue").then(m => m.default || m)
  }
]