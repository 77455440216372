import components_plugin_KR1HBZs4kY from "/home/runner/work/cadastro-portal/cadastro-portal/.nuxt/components.plugin.mjs";
import vueuse_head_plugin_D7WGfuP1A0 from "/home/runner/work/cadastro-portal/cadastro-portal/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import router_Pg0DINazwm from "/home/runner/work/cadastro-portal/cadastro-portal/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import prefetch_client_3cwHvxIDEX from "/home/runner/work/cadastro-portal/cadastro-portal/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import payload_client_5Om5dvb8Jc from "/home/runner/work/cadastro-portal/cadastro-portal/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import _0_toast_client_eBixQl5lG1 from "/home/runner/work/cadastro-portal/cadastro-portal/plugins/0.toast.client.ts";
import _1_gtag_client_47mDtbRmw8 from "/home/runner/work/cadastro-portal/cadastro-portal/plugins/1.gtag.client.ts";
import _1_sentry_client_YROiseFzU8 from "/home/runner/work/cadastro-portal/cadastro-portal/plugins/1.sentry.client.ts";
import _2_request_client_FIlPyppVFN from "/home/runner/work/cadastro-portal/cadastro-portal/plugins/2.request.client.ts";
import _3_casl_client_MJGaUtwVkn from "/home/runner/work/cadastro-portal/cadastro-portal/plugins/3.casl.client.ts";
import _3_firebase_client_RXk17aLfqB from "/home/runner/work/cadastro-portal/cadastro-portal/plugins/3.firebase.client.ts";
import logger_client_NoN5FB4OWs from "/home/runner/work/cadastro-portal/cadastro-portal/plugins/logger.client.ts";
import lottie_client_MJBevkcINk from "/home/runner/work/cadastro-portal/cadastro-portal/plugins/lottie.client.ts";
import posthog_client_fUucxKWhZE from "/home/runner/work/cadastro-portal/cadastro-portal/plugins/posthog.client.ts";
export default [
  components_plugin_KR1HBZs4kY,
  vueuse_head_plugin_D7WGfuP1A0,
  router_Pg0DINazwm,
  prefetch_client_3cwHvxIDEX,
  payload_client_5Om5dvb8Jc,
  _0_toast_client_eBixQl5lG1,
  _1_gtag_client_47mDtbRmw8,
  _1_sentry_client_YROiseFzU8,
  _2_request_client_FIlPyppVFN,
  _3_casl_client_MJGaUtwVkn,
  _3_firebase_client_RXk17aLfqB,
  logger_client_NoN5FB4OWs,
  lottie_client_MJBevkcINk,
  posthog_client_fUucxKWhZE
]