import { ref, reactive } from "vue";
import HttpUsuario from "@/http/usuario";

import type { ListCentralizadorDadosResponse } from "@/types/requests";
import type {
  ListUsuarioResponse,
  ListUsuarioParams,
  UserInformationResponse,
  CreateUsuarioRequest,
  CreateUsuarioResponse,
  GetUsuarioResponse,
  UpdateUsuarioRequest,
  UpdateUsuarioResponse,
  FidelizaBandeiraInformacaoResponse,
} from "@/types/usuario/requests";

const useCentralizadorDadosStorage = () => {
  const loggedUserInfo = ref(null as UserInformationResponse);

  const loading = ref(false);

  const list = reactive({
    usuarios: [],
    pagina: 1,
    totalPaginas: 0,
    limite: 25,
    totalRegistros: 0,
  } as ListUsuarioResponse);

  const listFocalFebrafar = ref([] as ListCentralizadorDadosResponse);

  const listaBandeirasHabilitadasFideliza = ref(
    [] as FidelizaBandeiraInformacaoResponse[]
  );

  const fetchFocalFebrafar = async () => {
    try {
      const data = await HttpUsuario.listFocalFebrafar();
      listFocalFebrafar.value = data;
    } catch {}
  };

  const verifyEmailExist = HttpUsuario.verifyEmailExist;

  const verifyCPFExist = HttpUsuario.verifyCPFExist;

  const inviteUser = HttpUsuario.inviteUser;

  const remove = async (id: number): Promise<void> => {
    loading.value = true;
    try {
      return await HttpUsuario.remove(id);
    } catch (error) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const fetch = async (
    params: ListUsuarioParams = { pagina: 1, limite: 25 },
    onlyCallback: boolean = false
  ) => {
    // se for só para chamar a requisição, não seta loading, list
    // nem nada
    if (onlyCallback) {
      try {
        return await HttpUsuario.list(params);
      } catch (error) {
        throw error;
      }
    }

    loading.value = true;

    try {
      const data = await HttpUsuario.list(params);
      list.usuarios = data.usuarios;
      list.pagina = data.pagina;
      list.totalPaginas = data.totalPaginas;
      list.limite = data.limite;
      list.totalRegistros = data.totalRegistros;
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  };

  const create = async (
    body: CreateUsuarioRequest
  ): Promise<CreateUsuarioResponse> => {
    loading.value = true;
    try {
      return await HttpUsuario.create(body);
    } catch (error) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const find = async (id: number): Promise<GetUsuarioResponse> => {
    loading.value = true;
    try {
      return await HttpUsuario.find(id);
    } catch (error) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const update = async (
    body: UpdateUsuarioRequest
  ): Promise<UpdateUsuarioResponse> => {
    loading.value = true;
    try {
      return await HttpUsuario.update(body);
    } catch (error) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  return reactive({
    loggedUserInfo,
    loading,
    list,
    listFocalFebrafar,
    listaBandeirasHabilitadasFideliza,
    fetchFocalFebrafar,
    verifyEmailExist,
    verifyCPFExist,
    inviteUser,
    fetch,
    create,
    find,
    update,
    remove,
  });
};

export default useCentralizadorDadosStorage();
