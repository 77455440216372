import type {
  ListUsuarioFocalFebrafar,
  VerifyEmailExistResponse,
  VerifyCPFExistResponse,
} from "@/types/requests";

import type {
  InviteUserRequestBody,
  InviteUserResponse,
} from "@/types/convite/requests";
import {
  ListUsuarioParams,
  ListUsuarioResponse,
  UserInformationResponse,
  CreateUsuarioRequest,
  CreateUsuarioResponse,
  GetUsuarioResponse,
  UpdateUsuarioRequest,
  UpdateUsuarioResponse,
  FidelizaBandeiraInformacaoResponse,
} from "@/types/usuario/requests";
import { produtoEnum } from "@/types/enums";

const usuario = {
  listFocalFebrafar(): Promise<ListUsuarioFocalFebrafar> {
    return useNuxtApp().$request({
      method: "GET",
      endpoint: "/usuarios/focal-febrafar",
      product: produtoEnum.CADASTRO,
    });
  },

  verifyEmailExist(email: string): Promise<VerifyEmailExistResponse> {
    return useNuxtApp().$request({
      method: "GET",
      endpoint: `/usuarios/verifica-existencia-email/${email}`,
      product: produtoEnum.CADASTRO,
    });
  },

  verifyCPFExist(cpf: string): Promise<VerifyCPFExistResponse> {
    return useNuxtApp().$request({
      method: "GET",
      endpoint: `/usuarios/verifica-existencia-cpf/${cpf}`,
      product: produtoEnum.CADASTRO,
    });
  },

  inviteUser(
    contatoId: number,
    body?: InviteUserRequestBody
  ): Promise<InviteUserResponse> {
    return useNuxtApp().$request({
      method: "POST",
      endpoint: `/convites/${contatoId}`,
      body,
      product: produtoEnum.CADASTRO,
    });
  },

  getUserInformation(product?: produtoEnum): Promise<UserInformationResponse> {
    return useNuxtApp().$request({
      method: "GET",
      endpoint: "/usuarios/informacoes",
      product: product ?? produtoEnum.CADASTRO,
    });
  },

  consultaBandeirasFideliza(): Promise<FidelizaBandeiraInformacaoResponse[]> {
    return useNuxtApp().$request({
      method: "GET",
      endpoint: "/api/fidelizamais/bandeiras/informacoes",
      product: produtoEnum.ORION,
      naoAdicionarPrefixo: true,
    });
  },

  list(body: ListUsuarioParams): Promise<ListUsuarioResponse> {
    // remove limite e página do body
    const { limite, pagina, query, ...finalBody } = body;

    return useNuxtApp().$request({
      method: "POST",
      endpoint: `/usuarios/listar?pagina=${pagina}&limite=${limite}`,
      product: produtoEnum.CADASTRO,
      body: {
        ...finalBody,
        query: query != null ? query.trim() : null,
      },
    });
  },

  create(body: CreateUsuarioRequest): Promise<CreateUsuarioResponse> {
    return useNuxtApp().$request({
      method: "POST",
      endpoint: "/usuarios",
      body,
      product: produtoEnum.CADASTRO,
      timeout: 2 * 60 * 1000,
    });
  },

  find(id: number): Promise<GetUsuarioResponse> {
    return useNuxtApp().$request({
      method: "GET",
      endpoint: `/usuarios/${id}`,
      product: produtoEnum.CADASTRO,
    });
  },

  update(body: UpdateUsuarioRequest): Promise<UpdateUsuarioResponse> {
    const { id, ...sentBody } = body;
    return useNuxtApp().$request({
      method: "PUT",
      endpoint: `/usuarios/${id}`,
      body: sentBody,
      product: produtoEnum.CADASTRO,
      timeout: 2 * 60 * 1000,
    });
  },

  remove(id: number): Promise<void> {
    return useNuxtApp().$request({
      method: "DELETE",
      endpoint: `/usuarios/${id}`,
      product: produtoEnum.CADASTRO,
      timeout: 2 * 60 * 1000,
    });
  },

  // TODO: Fixar quando o endpoint estiver pronto
  export(body: any): Promise<any> {
    return useNuxtApp().$request({
      method: "POST",
      endpoint: "/usuarios/exportar-usuarios",
      product: produtoEnum.CADASTRO,
      body,
      responseType: "blob",
      timeout: 2 * 60 * 1000,
    });
  },
};

export default usuario;
