import validate from "/home/runner/work/cadastro-portal/cadastro-portal/node_modules/nuxt/dist/pages/runtime/validate.mjs";
import auth_45global from "/home/runner/work/cadastro-portal/cadastro-portal/middleware/auth.global.ts";
export const globalMiddleware = [
  validate,
  auth_45global
]
export const namedMiddleware = {
  "grupo-acesso": () => import("/home/runner/work/cadastro-portal/cadastro-portal/middleware/grupo-acesso.ts"),
  home: () => import("/home/runner/work/cadastro-portal/cadastro-portal/middleware/home.ts"),
  loja: () => import("/home/runner/work/cadastro-portal/cadastro-portal/middleware/loja.ts"),
  performance: () => import("/home/runner/work/cadastro-portal/cadastro-portal/middleware/performance.ts"),
  rede: () => import("/home/runner/work/cadastro-portal/cadastro-portal/middleware/rede.ts"),
  "socio-honorario": () => import("/home/runner/work/cadastro-portal/cadastro-portal/middleware/socio-honorario.ts"),
  usuario: () => import("/home/runner/work/cadastro-portal/cadastro-portal/middleware/usuario.ts")
}