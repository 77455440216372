import colors from "tailwindcss/colors";
import axios from "axios";
import { getAuth, getIdToken } from "firebase/auth";

type httpMethod =
  | "GET"
  | "POST"
  | "PUT"
  | "DELETE"
  | "PATCH"
  | "HEAD"
  | "OPTIONS";

type options = {
  method: httpMethod;
  endpoint: string;
  product: string;
  body?: any;
  headers?: any;
  timeout?: number;
  savedTimeout?: string;
  reqId?: number;
  responseType?: string;
  naoAdicionarPrefixo?: boolean;
};

export default defineNuxtPlugin(() => {
  const envConfig = useRuntimeConfig();
  const { $sentry } = useNuxtApp();
  // 60 segundos
  const TIMEOUT = 60 * 1000;

  let requests = {};
  const shouldDebug =
    window.sessionStorage.getItem("debug") !== null ||
    window.localStorage.getItem("debug") !== null ||
    process.env.NODE_ENV === "development";

  return {
    provide: {
      // permite gerar o próximo ID, tornando a requisição conhecida
      getNextReqId: getReqId,
      request: async (options: options) => doRequest(options),
    },
  };

  async function doRequest(options: options) {
    const endpoint =
      envConfig.endpointMap[options?.product] ?? envConfig.backendUrl;

    const prefix = `${endpoint}/v1`;

    const url = options.naoAdicionarPrefixo
      ? `${endpoint}${options.endpoint}`
      : prefix + options.endpoint;

    const oauthToken = window.localStorage.getItem("oauthToken");

    const reqId = options.reqId ? options.reqId : getReqId();
    options.reqId = reqId;

    const reqRef = requests[reqId]
      ? `${reqId}#${requests[reqId].execs + 1}`
      : reqId;

    if (shouldDebug) {
      console.time(`${reqRef}`);
    }

    options.savedTimeout = `${TIMEOUT / 1000}s`;
    if (!options.headers) {
      options.headers = {};
    }

    options.headers["content-type"] = "application/json";
    options.headers["accept"] = "application/json";
    options.headers["x-febra-product"] = options.product;
    options.headers["Authorization"] = oauthToken ? `Bearer ${oauthToken}` : "";

    if (options.timeout == null) {
      options.timeout = TIMEOUT;
    }

    const requestBody = {
      method: options.method,
      timeout: options.timeout,
      url,
      data: options.body,
      headers: options.headers,
    };

    if (options.responseType) {
      requestBody["responseType"] = options.responseType;
    }

    if (!requests[reqId]) {
      requests[reqId] = { body: requestBody, execs: 1 };
    } else {
      requests[reqId].execs++;
      if (requests[reqId].execs > 3) {
        // stop retrying the same request after 3 attempts
        throw new Error("Too many retried requests");
      }
    }

    if (shouldDebug) {
      logGroupMessage("info", options, reqRef, "start");
      if (options.body) {
        console.group("Body");
        console.log(options.body);
        console.groupEnd();
      }

      if (options.headers) {
        console.groupCollapsed("Headers");
        console.log(options.headers);
        console.groupEnd();
      }

      console.groupCollapsed("Request Url");
      console.log(url);
      console.groupEnd();

      console.groupEnd();
    }
    try {
      // passa o id da requisição para salvar estado atual
      const resp = await axios(requestBody);
      if (shouldDebug) {
        if (resp.status.toString()[0] === "2") {
          logGroupMessage("success", options, reqRef, resp.status);
        } else {
          logGroupMessage("error", options, reqRef, resp.status);
        }

        if (resp.data) {
          console.group(
            "%c ↳ Response  ",
            `
              background-color: ${colors.emerald[100]}; 
              color: ${colors.emerald[900]};
              padding: 6px;
              border-radius: 10px;`
          );
          console.log(resp.data);
          console.groupEnd();
        }

        if (resp.headers) {
          console.groupCollapsed("Response Headers");
          console.log(resp.headers);
          console.groupEnd();
        }

        if (resp.request) {
          console.groupCollapsed("Complete url");
          console.log(resp.request);
          console.groupEnd();
        }

        console.group("Request time");
        console.timeEnd(`${reqRef}`);
        console.groupEnd();

        console.groupEnd();
      }

      return resp.data;
    } catch (error) {
      if (shouldDebug) {
        if (error.message.includes("timeout of ")) {
          logGroupMessage("error", options, reqRef, "timeout");
          console.error(
            `${options.method} to ${url} timeout after ${options.timeout}ms`
          );
        } else {
          logGroupMessage(
            "error",
            options,
            reqRef,
            error.response?.status ? error.response.status : "error"
          );
          if (error.response?.data) {
            console.group(
              "%c ↳ Response  ",
              `
              background-color: ${colors.rose[100]}; 
              color: ${colors.rose[900]};
              padding: 6px;
              border-radius: 10px;`
            );
            console.log(error.response.data);
            console.groupEnd();
          }

          console.groupCollapsed(
            "%c ↳ Error  ",
            `
              background-color: ${colors.rose[100]}; 
              color: ${colors.rose[900]};
              padding: 6px;
              border-radius: 10px;`
          );
          console.error(error);
          console.groupEnd();
        }
        console.group("Request time");
        console.timeEnd(`${reqRef}`);
        console.groupEnd();

        console.groupEnd();
      }

      if (error.response?.status === 401) {
        const user = getAuth().currentUser;

        if (user) {
          const token = await getIdToken(user, true);
          if (token) {
            window.localStorage.setItem("oauthToken", token);
          } else {
            window.localStorage.removeItem("oauthToken");
          }
        }
        // retries the request after receiving a new oauth token
        return doRequest(options);
      }

      if (error.response) {
        $sentry.captureException(error);
        throw error.response;
      } else {
        throw error;
      }
    }
  }

  function logGroupMessage(logType, requestOptions, reqId, status) {
    switch (logType) {
      case "info":
        console.groupCollapsed(
          `%c➤ ${requestOptions.method}:${reqId}%c${requestOptions.endpoint} (start)`,
          ...logFormatter("sky")
        );
        break;
      case "success":
        console.groupCollapsed(
          `%c✔️ ${requestOptions.method}:${reqId}%c${requestOptions.endpoint} (${status})`,
          ...logFormatter("emerald")
        );
        break;
      case "error":
        console.groupCollapsed(
          `%c✘ ${requestOptions.method}:${reqId}%c${requestOptions.endpoint} (${status})`,
          ...logFormatter("rose")
        );
        break;
      default:
        break;
    }
  }

  function logFormatter(color: string): string[] {
    let lColorHex;
    let lBgColorHex;
    let rColorHex;
    let rBgColorHex;

    lColorHex = colors[color][50];
    lBgColorHex = colors[color][500];
    rColorHex = colors[color][900];
    rBgColorHex = colors[color][100];

    const radius = "12px";
    const paddingY = "6px";
    const paddingX = "12px";
    const fontSize = "12px";

    return [
      `
      color: ${lColorHex}; 
      font-weight: bold; 
      background-color: ${lBgColorHex}; 
      font-size: ${fontSize}; 
      border-top-left-radius: ${radius}; 
      border-bottom-left-radius: ${radius}; 
      padding-right: ${paddingX};
      padding-left: ${paddingX};
      padding-top: ${paddingY};
      padding-bottom: ${paddingY};
      `,
      `
      color: ${rColorHex}; 
      font-weight: regular; 
      background-color: ${rBgColorHex}; 
      font-size: ${fontSize}; 
      border-top-right-radius: ${radius}; 
      border-bottom-right-radius: ${radius}; 
      padding-right: ${paddingX};
      padding-left: ${paddingX};
      padding-top: ${paddingY};
      padding-bottom: ${paddingY};
      `,
    ];
  }

  function getReqId(): number {
    const idFromSessionStorage = window.sessionStorage.getItem("backendReqId");

    if (!idFromSessionStorage || idFromSessionStorage === null) {
      let reqId = 1;
      window.sessionStorage.setItem("backendReqId", reqId.toString());
      return reqId;
    }

    const reqId = parseInt(idFromSessionStorage) + 1;
    window.sessionStorage.setItem("backendReqId", reqId.toString());

    return reqId;
  }
});
